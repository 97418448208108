* {
  box-sizing: border-box;
}

.feedback-panel {
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: var(--light-blue-05);
  padding-bottom: 40px;
  flex: 1;
}

.feedback-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.cards-wrapper {
  display: flex;
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: stretch;
}
.card {
  width: 32%;
  /* height: 100%; */
  background-color: rgba(255, 255, 255, 1);
  border: solid 2px var(--light-blue-20);
  border-radius: 10px;
  padding: 25px;
  margin-bottom: 30px;
  box-shadow: 0 7px 10px rgba(10, 10, 10, 0.1);
  cursor: pointer;
}

.card:hover {
  background-color: rgba(255, 255, 255, 0.5);
  box-shadow: 0 0px 10px rgba(10, 10, 10, 0.1);
  /* pointer-events: none; */
}

.survey .card {
  width: 48%;
}

.survey .card-link {
  border: solid 2px var(--light-blue-50);
  border-radius: 5px;
  padding: 5px;
  text-decoration: none;
  margin-right: 10px;
  display: inline-block;
  margin-bottom: 10px;
  color: rgba(10, 10, 10, 0.8);
}
.survey .card-link:hover {
  background-color: var(--blue);
  color: rgba(255, 255, 255);
}

.card h3 {
  margin: 10px auto;
}

.card-base {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.card .counter {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
}

.card .counter h3 {
  margin: 0;
}

.card .counter p {
  font-size: 14px;
  margin: 0;
}

.full-card.dark {
  background-color: var(--blue);
}

.full-card {
  /* height: 100%; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

/*  Survey */

/* FeedbackForm */
.feedback-form {
  max-width: 800px;
  display: flex;
  flex-direction: column;
}
.feedback-form form {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
}

.feedback-form .form-control {
  width: 48%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 30px;
  background-color: rgba(255, 255, 255, 0.9);
  border: solid 1px var(--light-blue-20);
  padding: 10px 15px;
  border-radius: 10px;
}

.feedback-form .form-control span {
  margin-bottom: 30px;
  font-weight: bold;
  text-align: center;
}
.comment-reply {
  width: 100%;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.comment-reply h4 {
  max-width: 80%;
}
.feedback-form textarea {
  resize: none;
  width: 100%;
  border: solid 1px var(--light-blue-50);
  border-radius: 5px;
  padding: 10px;
  background-color: rgba(255, 255, 255, 0.9);
}

.feedback-form .center {
  width: 100%;
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.survey-slider {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.survey-slider ul {
  width: 100%;
  list-style: none;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  margin: 10px 0;
}
.survey-slider ul li {
  font-size: 14px;
  padding: 0;
  margin: 0;
}

/* The slider itself */
.slider {
  -webkit-appearance: none; /* Override default CSS styles */
  appearance: none;
  width: 100%; /* Full-width */
  height: 5px !important; /* Specified height */
  padding: 0 !important;
  background: rgba(10, 10, 10, 0.1); /* Grey background */
  outline: none; /* Remove outline */
  opacity: 0.8; /* Set transparency (for mouse-over effects on hover) */
  -webkit-transition: 0.2s; /* 0.2 seconds transition on hover */
  transition: opacity 0.2s;
}

/* Mouse-over effects */
.slider:hover {
  opacity: 1; /* Fully shown on mouse-over */
}

/* The slider handle (use -webkit- (Chrome, Opera, Safari, Edge) and -moz- (Firefox) to override default look) */
.slider::-webkit-slider-thumb {
  -webkit-appearance: none; /* Override default look */
  appearance: none;
  width: 30px; /* Set a specific slider handle width */
  height: 30px; /* Slider handle height */
  background: var(--blue); /* Green background */
  cursor: pointer; /* Cursor on hover */
  border-radius: 50%;
  border: transparent;
}

.slider::-moz-range-thumb {
  width: 30px; /* Set a specific slider handle width */
  height: 30px; /* Slider handle height */
  background: var(--blue); /* Green background */
  cursor: pointer; /* Cursor on hover */
  border-radius: 50%;
  border: transparent;
}
.alert {
  color: rgba(10, 25, 10, 1);
  padding: 10px;
  width: 100%;
  margin: 20px auto;
  text-align: center;
  display: block;
  background: rgba(150, 255, 150, 0.1);
  border-radius: 5px;
  box-shadow: 0 5px 10px 5px rgba(10, 10, 10, 0.05);
}
