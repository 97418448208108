.banner {
  min-height: 400px;
  background-color: var(--light-blue-05);
  display: flex;
  flex-direction: column;
  align-items: center;
}
.banner-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  text-align: left;
  padding: 0 50px;
}
.banner .side {
}
.banner-left {
  width: 55%;
  /* background-color: antiquewhite; */
}
.banner-right {
  /* background-color: beige; */
  width: 45%;
  padding: 100px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.title-wrapper {
}

h1 {
  font-size: 54px;
  margin: 15px auto;
  color: var(--dark-blue);
}
