.left {
  /* background-color: rgba(20, 20, 100, 0.1); */
  align-items: flex-start;
}
.right {
  /* background-color: rgba(10, 200, 50, 0.1); */
  align-items: flex-end;
}
.center {
  align-items: center;
}
.half {
  width: 45%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.col ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: row;
}

.header ul li {
  padding: 0;
  margin: 0 0 0 25px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.header li a {
  text-decoration: none;
  color: var(--blue);
}

.header li:hover h4 {
  color: var(--blue);
}

li h4,
li h5 {
  margin: 0 auto;
}

.avatar {
  background-color: var(--blue);
  color: rgba(255, 255, 255, 0.95);
  padding: 5px 10px;
  border-radius: 10px;
  display: flex;
}

.logo img {
  height: 50px;
}
.header {
  height: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-bottom: 2px solid var(--light-blue-50);
}
.header-wrapper {
  width: 100%;
  height: 100%;
}

.hamburger {
  height: 40px;
  cursor: pointer;
  display: none;
}

.mobile-menu {
  display: none;
  height: 100vh;
  width: 100vw;
  position: absolute;
  background-color: rgba(255, 255, 255, 0.95);
  z-index: 5;
  padding: 50px;
}

.mobile-menu ul {
  padding: 0;
  list-style: none;
}

.mobile-menu ul li {
  padding: 10px;
  list-style: none;
  margin-bottom: 15px;
  margin-left: 0px;
}
.mobile-menu ul li a {
  text-decoration: none;
  color: var(--blue);
}

.hide {
  display: none;
}
