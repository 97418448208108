.profile {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  background-color: var(--light-blue-05);
  flex: 1;
}

.profile-wrapper {
  max-width: 800px;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 30px;
  align-items: center;
}
.profile h4 {
  text-align: center;
}

.profile .center {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
}

.profile .form-control {
  width: 45%;
}

.profile form {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-evenly;
}

.profile select {
  height: 40px;
  padding: 0 10px;
}

.notice {
  width: 60%;
  color: rgba(50, 150, 50, 0.9);
  font-weight: bold;
  padding: 10px;
  margin: 0 auto 20px auto;
  text-align: center;
  display: inline-block;
  background: rgba(150, 255, 150, 0.2);
  border-radius: 5px;
}
.profile .button {
  margin: 0 15px;
}
