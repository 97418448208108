.insight-panel {
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: var(--light-blue-05);
  flex: 1;
}

.insight-panel-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.page-header b {
  font-size: 28px;
}
.insight-panel .green {
  background-color: var(--blue);
  color: rgba(255, 255, 255, 1);
  padding: 0px 10px;
}

.pie-chart-box {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
}

.pie-chart-box .chart {
  width: 30%;
  max-height: 300px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  /* min-height: 200px; */
}
.pie-chart-box .chart > div {
  max-height: 200px;
  height: 180px !important;
}

.insight-principles-wrapper {
  margin: 20px 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.insight-box {
  background-color: var(--light-blue-10);
  border: solid 1px var(--light-blue-10);
  border-radius: 10px;
  padding: 30px 40px;
  margin-bottom: 30px;
  width: 95%;
}
.insight-box-header {
  cursor: pointer;
}

.insight-box.expanded .insight-box-header {
  background-color: var(--blue);
  border: solid 1px rgba(10, 10, 10, 0.05);
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  margin-bottom: 20px;
  color: #fff;
}
.insight-box.expanded .open {
  display: none;
}
.insight-box.expanded .close {
  width: 30px;
}
.insight-box.collapsed {
  padding: 20px 30px;
  cursor: pointer;
}
.insight-box.collapsed .insight-box-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0;
}
.insight-box.collapsed .top-box,
.insight-box.collapsed .chart-box,
.insight-box.collapsed .comment-box-wrapper,
.insight-box.collapsed .close {
  display: none;
}

.top-box {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  line-height: 22px;
}
.top-box .part {
  /* width: 48%; */
  padding: 20px;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.top-box p {
  margin: 0;
}
.top-box .principle-text {
  padding: 32px 20px;
  max-width: 40%;
}
.top-box .principle-questions {
  display: flex;
  max-width: 55%;
}
.top-box .principle-questions ul {
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.top-box .principle-questions li {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 5px 0;
}
.top-box .principle-questions li b {
  margin-right: 10px;
}
.chart-box {
  width: 100%;
  display: flex;
  margin: 30px 0;
}
.chart-box .chart {
  min-height: 200px;
  flex: 1;
}
.comment-box-wrapper {
  margin-top: 20px;
  margin-bottom: 10px;
}
.comment-box-wrapper h4 {
  margin: 20px 0;
}
.comment-box {
  background-color: rgba(255, 255, 255, 0.7);
  border-radius: 15px;
  padding: 30px;
  line-height: 22px;
}
.comment-box p {
  margin: 0;
}
.comment-box ul {
  padding: 10px 0;
  margin: 0;
  list-style: none;
  max-height: 320px;
  overflow: scroll;
}
.comment-box li {
  margin-bottom: 20px;
  padding: 0;
}

.comment-box li:last-child,
.comment-box li:last-child .comment-body {
  border: none;
  padding-bottom: 0;
  margin-bottom: 0;
}
.principle-toggle {
  cursor: pointer;
  width: 20px;
  display: inline-block !important;
}
.principle-toggle:hover {
  transform: scale(1.5, 1.5);
}

.mobile {
  display: none;
}
.desktop {
  display: inline-block;
}
