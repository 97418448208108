.loading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 50px;
  text-align: center;
}

.loading svg {
  margin: 0;
  height: 80px;
}

.loading span {
  margin-bottom: -10px;
}
