* {
  transition: 0.5s;
  transition-timing-function: ease-out;
}
html {
  position: relative; /* Allows the footer to notice content height */
  min-height: 100vh; /* My page will allways take the full screen */
}
:root {
  --dark-blue: rgba(46, 111, 149, 1);
  --blue: rgba(46, 111, 149, 1);
  --light-blue-10: rgba(46, 111, 149, 0.1);
  --light-blue-20: rgba(46, 111, 149, 0.2);
  --light-blue-50: rgba(46, 111, 149, 0.5);
  --light-blue-05: rgba(46, 111, 149, 0.05);
}
body {
  margin: 0;
  font-family: "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  user-select: none;
} */

p {
  line-height: 22px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
  cursor: pointer;
}
.no-scroll {
  overflow: hidden;
}

.page-header {
  margin: 30px 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 80%;
  max-width: 500px;
}

h4 {
  color: rgba(10, 10, 10, 0.8);
}

.page-header h3 {
  text-align: center;
}
.page-header h4 {
  text-align: center;
}

form select {
  background-color: rgba(255, 255, 255, 1);
  border: solid thin rgba(46, 111, 149, 0.2);
  border: solid thin var(--light-blue-20);
  border-radius: 10px;
}

@media screen and (max-width: 1240px) {
  .feedback-wrapper,
  .insight-panel-wrapper {
    padding: 0 2% !important;
  }
}

@media screen and (max-width: 900px) {
  .header {
    height: 80px !important;
  }
  .menu-list {
    display: none !important;
  }
  .logo img {
    height: 30px !important;
  }
  .hamburger,
  .mobile-menu {
    display: inline-block !important;
  }
  .banner {
    min-height: 250px;
  }
  .banner-left {
    width: 60% !important;
    /* background-color: antiquewhite; */
  }
  .banner-right {
    /* background-color: beige; */
    width: 40% !important;
    padding: 50px 0 !important;
  }
  .banner-right img {
    height: 200px;
  }

  h1 {
    font-size: 36px !important;
    margin: 15px auto;
  }
  .intro {
    min-height: 200px !important ;
  }
  .intro h3 {
    font-size: 18px !important;
  }
  .cta {
    min-height: 200px !important;
  }
  .cta-wrapper {
    padding: 40px !important;
  }
  .cta h3 {
    font-size: 18px !important;
  }

  .cards-wrapper {
    justify-content: space-evenly !important;
  }
  .card {
    width: 45% !important;
  }

  .feedback-form form {
    justify-content: center !important;
  }
  .feedback-form .form-control {
    width: 60% !important;
  }
  .comment-reply {
    width: 60% !important;
  }
  .comment-reply h4 {
    max-width: 100% !important;
  }

  .profile form {
    padding: 0 1%;
  }
  .pie-chart-box {
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }

  .pie-chart-box .chart {
    width: 40% !important;
  }
}

@media screen and (max-width: 720px) {
  .button {
    padding: 10px !important;
  }
  .banner-wrapper {
    flex-direction: column !important;
    text-align: center !important;
    padding: 0 !important;
    padding-top: 50px !important;
  }
  .side {
    width: 100% !important;
  }
  .banner-right {
    padding: 0 !important;
    margin: 0 !important;
  }
  .banner-right img {
    margin-bottom: -10px;
    margin-top: 40px;
  }

  .intro-wrapper {
    flex-direction: column !important;
    padding: 30px 0;
  }
  .intro .col {
    width: 100% !important;
    padding: 0 30px;
  }
  .intro h3 {
    text-align: center !important;
  }
  .pointers-wrapper {
    padding: 30px 0 !important;
  }
  .pointer {
    width: 60% !important;
  }

  .feedback-panel {
    padding-bottom: 20px !important;
  }
  .profile form {
    justify-content: center !important;
  }
  .profile .form-control {
    width: 70% !important;
  }

  .mobile {
    display: inline-block !important;
  }
  .desktop {
    display: none !important;
  }
  .pie-chart-box .chart {
    width: 80% !important;
  }
  .top-box {
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center;
  }

  .top-box .principle-text {
    width: 80% !important;
    max-width: 96% !important;
  }

  .top-box .principle-questions {
    width: 80% !important;
    max-width: 960% !important;
  }

  .footer-wrapper {
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;
  }
  .footer-wrapper h4 {
    font-size: 14px !important;
    margin: 5px 0 !important;
    text-align: center !important;
  }
}

@media screen and (max-width: 600px) {
  .card {
    width: 80% !important;
  }
  .feedback-form .form-control {
    width: 80% !important;
  }
  .profile .form-control {
    width: 80% !important;
  }
  .comment-reply {
    width: 80% !important;
  }

  .insight-box {
    margin-right: 2% !important;
    margin-left: 2% !important;
    padding: 20px !important;
  }
  .top-box .principle-text {
    width: 100% !important;
  }

  .top-box .principle-questions {
    width: 100% !important;
  }

  .comment-box {
    border-radius: 10px !important;
    padding: 20px 10px !important;
  }
  .comment-box ul {
    padding: 10px 10px !important;
  }
}

@media screen and (max-width: 520px) {
  .insight-box {
    width: 90% !important;
    max-width: 90% !important;
  }
  .chart-box .chart {
    max-width: 100% !important;
  }
  .top-box .principle-text {
    padding: 10px !important;
  }
  .top-box .principle-questions {
    padding: 10px !important;
  }
  .comment-avatar {
    width: 30px !important;
    height: 30px !important;
    min-width: 30px !important;
    min-height: 30px !important;
    margin-right: 10px !important;
  }
  .comment-meta {
    font-size: 14px !important;
    flex-wrap: wrap;
  }
  .comment-vote {
    width: 40% !important;
  }
  .comment-meta div {
    margin-right: 10px !important;
  }
  .comment-date {
    margin-top: 5px !important;
  }
}

@media screen and (max-width: 480px) {
  .button {
    padding: 7px 10px !important;
  }
  h1 {
    font-size: 28px !important;
    padding: 0 15px;
    margin: 15px auto;
  }
  .card {
    width: 95% !important;
  }
  .feedback-form .form-control {
    width: 90% !important;
  }
  .profile .form-control {
    width: 90% !important;
  }
  .comment-reply {
    width: 90% !important;
  }
}

.App {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.body-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  padding-bottom: 100px;
}

.full {
  width: 100vw;
  left: 0;
}

.wrapped {
  max-width: 1200px;
}

.banner {
  min-height: 400px;
  background-color: var(--light-blue-05);
  display: flex;
  flex-direction: column;
  align-items: center;
}
.banner-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  text-align: left;
  padding: 0 50px;
}
.banner .side {
}
.banner-left {
  width: 55%;
  /* background-color: antiquewhite; */
}
.banner-right {
  /* background-color: beige; */
  width: 45%;
  padding: 100px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.title-wrapper {
}

h1 {
  font-size: 54px;
  margin: 15px auto;
  color: var(--dark-blue);
}

.button {
  padding: 15px;
  display: inline-flex;
  margin: 0;
}

.default {
  background-color: rgba(10, 10, 10, 0.9);
  border-radius: 5px;
  color: rgba(255, 255, 255, 1);
}
.default:hover {
  cursor: pointer;
  background-color: var(--blue);
}

.button span {
  font-weight: 700;
}

.button.dark {
  color: rgba(10, 10, 10, 0.9);
  background-color: rgba(255, 255, 255, 1);
}

.button.light {
  color: rgba(10, 10, 10, 0.9);
  background-color: transparent;
  border: 2px solid var(--blue);
}

.button.hide {
  display: none !important;
}

.dark {
  background-color: var(--blue);
  color: rgba(255, 255, 255, 1);
}

.intro {
  min-height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.intro-wrapper {
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.intro h3 {
  font-size: 24px;
  text-align: left;
}

.intro .col {
  width: 45%;
}

.row {
}
.cta {
  min-height: 300px;
  color: rgba(255, 255, 255, 1);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.cta-wrapper {
  height: 100%;
  width: 100%;
  background-color: var(--blue);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 50px 0;
}

.cta h3 {
  font-size: 24px;
  max-width: 680px;
}

.pointers {
  min-height: 320px;
  background-color: rgba(255, 255, 255, 1);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.pointers-wrapper {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
}

.pointer {
  width: 34%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 0 2%;
}

.pointer img {
  width: 40px;
  margin-right: 20px;
}

.popup {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.popup-box {
  background-color: rgba(255, 255, 255, 1);
  display: flex;
  flex-direction: column;
  min-width: 400px;
  padding: 40px;
  border: solid 2px var(--light-blue-50);
  border-radius: 10px;
  position: relative;
  z-index: 5;
  margin-top: -20%;
  box-shadow: 0 15px 15px 10px rgba(10, 10, 10, 0.2);
}
.popup-box span {
  margin-bottom: 20px;
}
.popup-box code {
  padding: 10px 5px;
  border: solid thin var(--light-blue-20);
  border-radius: 5px;
}
.popup-box code:hover {
  background-color: var(--light-blue-50);
}

.loading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 50px;
  text-align: center;
}

.loading svg {
  margin: 0;
  height: 80px;
}

.loading span {
  margin-bottom: -10px;
}

.left {
  /* background-color: rgba(20, 20, 100, 0.1); */
  align-items: flex-start;
}
.right {
  /* background-color: rgba(10, 200, 50, 0.1); */
  align-items: flex-end;
}
.center {
  align-items: center;
}
.half {
  width: 45%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.col ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: row;
}

.header ul li {
  padding: 0;
  margin: 0 0 0 25px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.header li a {
  text-decoration: none;
  color: var(--blue);
}

.header li:hover h4 {
  color: var(--blue);
}

li h4,
li h5 {
  margin: 0 auto;
}

.avatar {
  background-color: var(--blue);
  color: rgba(255, 255, 255, 0.95);
  padding: 5px 10px;
  border-radius: 10px;
  display: flex;
}

.logo img {
  height: 50px;
}
.header {
  height: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-bottom: 2px solid var(--light-blue-50);
}
.header-wrapper {
  width: 100%;
  height: 100%;
}

.hamburger {
  height: 40px;
  cursor: pointer;
  display: none;
}

.mobile-menu {
  display: none;
  height: 100vh;
  width: 100vw;
  position: absolute;
  background-color: rgba(255, 255, 255, 0.95);
  z-index: 5;
  padding: 50px;
}

.mobile-menu ul {
  padding: 0;
  list-style: none;
}

.mobile-menu ul li {
  padding: 10px;
  list-style: none;
  margin-bottom: 15px;
  margin-left: 0px;
}
.mobile-menu ul li a {
  text-decoration: none;
  color: var(--blue);
}

.hide {
  display: none;
}

.footer {
  background-color: rgba(255, 255, 255, 1);
  height: 100px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: absolute;
  bottom: 0;
  left: 0;
}

.footer-wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 50px;
}

.signin {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  background-color: var(--light-blue-05);
  flex: 1 1;
}

.signin-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.signin h4 {
  text-align: center;
  margin: 0 auto;
}

.auth_notice h4 {
  margin-left: 10px;
}

.signin form {
  min-width: 340px;
}

.error {
  color: rgba(255, 100, 100, 0.9);
  padding: 10px;
  margin: 10px auto;
  text-align: center;
  display: block;
  background: rgba(255, 150, 150, 0.2);
  border-radius: 5px;
}

.form-wrapper {
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.form-control {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-bottom: 25px;
}

.signin .center {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
}

.form-control label {
  margin-bottom: 5px;
  font-weight: 700;
  font-size: 14px;
}

.form-control input {
  height: 40px;
  border-radius: 5px;
  padding: 0 10px;
  font-weight: 500;
  background-color: rgba(255, 255, 255, 1);
  border: solid 1px var(--light-blue-20);
  color: rgba(10, 10, 10, 0.5);
}

.form-control input:hover {
  border: solid 1px rgba(10, 10, 10, 0.1);
}

.auth_notice {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  margin: 15px auto;
}
.notice h4 {
  margin-left: 5px;
}

.pointer-area {
  cursor: pointer;
}

.consent-box {
  max-width: 340px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
}
.consent-box span {
  font-size: 14px;
  margin: 5px 0;
  text-align: left;
}
/* .consent-box input {
  max-width: 20px;
  max-height: 20px;
} */

* {
  box-sizing: border-box;
}

.feedback-panel {
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: var(--light-blue-05);
  padding-bottom: 40px;
  flex: 1 1;
}

.feedback-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.cards-wrapper {
  display: flex;
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: stretch;
}
.card {
  width: 32%;
  /* height: 100%; */
  background-color: rgba(255, 255, 255, 1);
  border: solid 2px var(--light-blue-20);
  border-radius: 10px;
  padding: 25px;
  margin-bottom: 30px;
  box-shadow: 0 7px 10px rgba(10, 10, 10, 0.1);
  cursor: pointer;
}

.card:hover {
  background-color: rgba(255, 255, 255, 0.5);
  box-shadow: 0 0px 10px rgba(10, 10, 10, 0.1);
  /* pointer-events: none; */
}

.survey .card {
  width: 48%;
}

.survey .card-link {
  border: solid 2px var(--light-blue-50);
  border-radius: 5px;
  padding: 5px;
  text-decoration: none;
  margin-right: 10px;
  display: inline-block;
  margin-bottom: 10px;
  color: rgba(10, 10, 10, 0.8);
}
.survey .card-link:hover {
  background-color: var(--blue);
  color: rgba(255, 255, 255);
}

.card h3 {
  margin: 10px auto;
}

.card-base {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.card .counter {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
}

.card .counter h3 {
  margin: 0;
}

.card .counter p {
  font-size: 14px;
  margin: 0;
}

.full-card.dark {
  background-color: var(--blue);
}

.full-card {
  /* height: 100%; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

/*  Survey */

/* FeedbackForm */
.feedback-form {
  max-width: 800px;
  display: flex;
  flex-direction: column;
}
.feedback-form form {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
}

.feedback-form .form-control {
  width: 48%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 30px;
  background-color: rgba(255, 255, 255, 0.9);
  border: solid 1px var(--light-blue-20);
  padding: 10px 15px;
  border-radius: 10px;
}

.feedback-form .form-control span {
  margin-bottom: 30px;
  font-weight: bold;
  text-align: center;
}
.comment-reply {
  width: 100%;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.comment-reply h4 {
  max-width: 80%;
}
.feedback-form textarea {
  resize: none;
  width: 100%;
  border: solid 1px var(--light-blue-50);
  border-radius: 5px;
  padding: 10px;
  background-color: rgba(255, 255, 255, 0.9);
}

.feedback-form .center {
  width: 100%;
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.survey-slider {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.survey-slider ul {
  width: 100%;
  list-style: none;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  margin: 10px 0;
}
.survey-slider ul li {
  font-size: 14px;
  padding: 0;
  margin: 0;
}

/* The slider itself */
.slider {
  -webkit-appearance: none; /* Override default CSS styles */
  appearance: none;
  width: 100%; /* Full-width */
  height: 5px !important; /* Specified height */
  padding: 0 !important;
  background: rgba(10, 10, 10, 0.1); /* Grey background */
  outline: none; /* Remove outline */
  opacity: 0.8; /* Set transparency (for mouse-over effects on hover) */ /* 0.2 seconds transition on hover */
  transition: opacity 0.2s;
}

/* Mouse-over effects */
.slider:hover {
  opacity: 1; /* Fully shown on mouse-over */
}

/* The slider handle (use -webkit- (Chrome, Opera, Safari, Edge) and -moz- (Firefox) to override default look) */
.slider::-webkit-slider-thumb {
  -webkit-appearance: none; /* Override default look */
  appearance: none;
  width: 30px; /* Set a specific slider handle width */
  height: 30px; /* Slider handle height */
  background: var(--blue); /* Green background */
  cursor: pointer; /* Cursor on hover */
  border-radius: 50%;
  border: transparent;
}

.slider::-moz-range-thumb {
  width: 30px; /* Set a specific slider handle width */
  height: 30px; /* Slider handle height */
  background: var(--blue); /* Green background */
  cursor: pointer; /* Cursor on hover */
  border-radius: 50%;
  border: transparent;
}
.alert {
  color: rgba(10, 25, 10, 1);
  padding: 10px;
  width: 100%;
  margin: 20px auto;
  text-align: center;
  display: block;
  background: rgba(150, 255, 150, 0.1);
  border-radius: 5px;
  box-shadow: 0 5px 10px 5px rgba(10, 10, 10, 0.05);
}

.insight-panel {
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: var(--light-blue-05);
  flex: 1 1;
}

.insight-panel-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.page-header b {
  font-size: 28px;
}
.insight-panel .green {
  background-color: var(--blue);
  color: rgba(255, 255, 255, 1);
  padding: 0px 10px;
}

.pie-chart-box {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
}

.pie-chart-box .chart {
  width: 30%;
  max-height: 300px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  /* min-height: 200px; */
}
.pie-chart-box .chart > div {
  max-height: 200px;
  height: 180px !important;
}

.insight-principles-wrapper {
  margin: 20px 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.insight-box {
  background-color: var(--light-blue-10);
  border: solid 1px var(--light-blue-10);
  border-radius: 10px;
  padding: 30px 40px;
  margin-bottom: 30px;
  width: 95%;
}
.insight-box-header {
  cursor: pointer;
}

.insight-box.expanded .insight-box-header {
  background-color: var(--blue);
  border: solid 1px rgba(10, 10, 10, 0.05);
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  margin-bottom: 20px;
  color: #fff;
}
.insight-box.expanded .open {
  display: none;
}
.insight-box.expanded .close {
  width: 30px;
}
.insight-box.collapsed {
  padding: 20px 30px;
  cursor: pointer;
}
.insight-box.collapsed .insight-box-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0;
}
.insight-box.collapsed .top-box,
.insight-box.collapsed .chart-box,
.insight-box.collapsed .comment-box-wrapper,
.insight-box.collapsed .close {
  display: none;
}

.top-box {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  line-height: 22px;
}
.top-box .part {
  /* width: 48%; */
  padding: 20px;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.top-box p {
  margin: 0;
}
.top-box .principle-text {
  padding: 32px 20px;
  max-width: 40%;
}
.top-box .principle-questions {
  display: flex;
  max-width: 55%;
}
.top-box .principle-questions ul {
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.top-box .principle-questions li {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 5px 0;
}
.top-box .principle-questions li b {
  margin-right: 10px;
}
.chart-box {
  width: 100%;
  display: flex;
  margin: 30px 0;
}
.chart-box .chart {
  min-height: 200px;
  flex: 1 1;
}
.comment-box-wrapper {
  margin-top: 20px;
  margin-bottom: 10px;
}
.comment-box-wrapper h4 {
  margin: 20px 0;
}
.comment-box {
  background-color: rgba(255, 255, 255, 0.7);
  border-radius: 15px;
  padding: 30px;
  line-height: 22px;
}
.comment-box p {
  margin: 0;
}
.comment-box ul {
  padding: 10px 0;
  margin: 0;
  list-style: none;
  max-height: 320px;
  overflow: scroll;
}
.comment-box li {
  margin-bottom: 20px;
  padding: 0;
}

.comment-box li:last-child,
.comment-box li:last-child .comment-body {
  border: none;
  padding-bottom: 0;
  margin-bottom: 0;
}
.principle-toggle {
  cursor: pointer;
  width: 20px;
  display: inline-block !important;
}
.principle-toggle:hover {
  -webkit-transform: scale(1.5, 1.5);
          transform: scale(1.5, 1.5);
}

.mobile {
  display: none;
}
.desktop {
  display: inline-block;
}

.chart {
  transition: none !important;
}

.single-comment {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
}
.comment-avatar {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  width: 50px;
  height: 50px;
  margin-right: 20px;
  border-radius: 50%;
  background-color: var(--light-blue-50);
  color: rgba(255, 255, 255, 0.9);
}
.comment-body {
  flex: 1 1;
  padding-bottom: 10px;
  border-bottom: solid 1px rgba(10, 10, 10, 0.1);
}
.comment-meta {
  display: flex;
  margin: 10px 0 15px 0;
  font-size: 14px;
  color: rgba(10, 10, 10, 0.6);
}
.comment-meta div {
  margin-right: 30px;
}
.comment-date span,
.comment-vote .bold {
  font-weight: bold;
}
.comment-vote {
  font-weight: light;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
}
.comment-vote span {
  margin-right: 5px;
}
.vote {
  margin-right: 2px;
  height: 20px;
  width: 20px;
}
.vote:hover {
  -webkit-transform: scale(1.3, 1.3);
          transform: scale(1.3, 1.3);
  transition: 0.2s;
}

.comment-vote .clicked {
  color: rgba(229, 11, 11, 0.8);
}

.profile {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  background-color: var(--light-blue-05);
  flex: 1 1;
}

.profile-wrapper {
  max-width: 800px;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 30px;
  align-items: center;
}
.profile h4 {
  text-align: center;
}

.profile .center {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
}

.profile .form-control {
  width: 45%;
}

.profile form {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-evenly;
}

.profile select {
  height: 40px;
  padding: 0 10px;
}

.notice {
  width: 60%;
  color: rgba(50, 150, 50, 0.9);
  font-weight: bold;
  padding: 10px;
  margin: 0 auto 20px auto;
  text-align: center;
  display: inline-block;
  background: rgba(150, 255, 150, 0.2);
  border-radius: 5px;
}
.profile .button {
  margin: 0 15px;
}

