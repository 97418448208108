* {
  transition: 0.5s;
  transition-timing-function: ease-out;
}
html {
  position: relative; /* Allows the footer to notice content height */
  min-height: 100vh; /* My page will allways take the full screen */
}
:root {
  --dark-blue: rgba(46, 111, 149, 1);
  --blue: rgba(46, 111, 149, 1);
  --light-blue-10: rgba(46, 111, 149, 0.1);
  --light-blue-20: rgba(46, 111, 149, 0.2);
  --light-blue-50: rgba(46, 111, 149, 0.5);
  --light-blue-05: rgba(46, 111, 149, 0.05);
}
body {
  margin: 0;
  font-family: "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  user-select: none;
}
/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  user-select: none;
} */

p {
  line-height: 22px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
  cursor: pointer;
}
.no-scroll {
  overflow: hidden;
}

.page-header {
  margin: 30px 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 80%;
  max-width: 500px;
}

h4 {
  color: rgba(10, 10, 10, 0.8);
}

.page-header h3 {
  text-align: center;
}
.page-header h4 {
  text-align: center;
}

form select {
  background-color: rgba(255, 255, 255, 1);
  border: solid thin var(--light-blue-20);
  border-radius: 10px;
}
