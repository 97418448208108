.dark {
  background-color: var(--blue);
  color: rgba(255, 255, 255, 1);
}

.intro {
  min-height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.intro-wrapper {
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.intro h3 {
  font-size: 24px;
  text-align: left;
}

.intro .col {
  width: 45%;
}

.row {
}
.cta {
  min-height: 300px;
  color: rgba(255, 255, 255, 1);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.cta-wrapper {
  height: 100%;
  width: 100%;
  background-color: var(--blue);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 50px 0;
}

.cta h3 {
  font-size: 24px;
  max-width: 680px;
}

.pointers {
  min-height: 320px;
  background-color: rgba(255, 255, 255, 1);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.pointers-wrapper {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
}

.pointer {
  width: 34%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 0 2%;
}

.pointer img {
  width: 40px;
  margin-right: 20px;
}
