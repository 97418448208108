.single-comment {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
}
.comment-avatar {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  width: 50px;
  height: 50px;
  margin-right: 20px;
  border-radius: 50%;
  background-color: var(--light-blue-50);
  color: rgba(255, 255, 255, 0.9);
}
.comment-body {
  flex: 1;
  padding-bottom: 10px;
  border-bottom: solid 1px rgba(10, 10, 10, 0.1);
}
.comment-meta {
  display: flex;
  margin: 10px 0 15px 0;
  font-size: 14px;
  color: rgba(10, 10, 10, 0.6);
}
.comment-meta div {
  margin-right: 30px;
}
.comment-date span,
.comment-vote .bold {
  font-weight: bold;
}
.comment-vote {
  font-weight: light;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
}
.comment-vote span {
  margin-right: 5px;
}
.vote {
  margin-right: 2px;
  height: 20px;
  width: 20px;
}
.vote:hover {
  transform: scale(1.3, 1.3);
  transition: 0.2s;
}

.comment-vote .clicked {
  color: rgba(229, 11, 11, 0.8);
}
