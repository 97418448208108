.App {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.body-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  padding-bottom: 100px;
}

.full {
  width: 100vw;
  left: 0;
}

.wrapped {
  max-width: 1200px;
}
