.popup {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.popup-box {
  background-color: rgba(255, 255, 255, 1);
  display: flex;
  flex-direction: column;
  min-width: 400px;
  padding: 40px;
  border: solid 2px var(--light-blue-50);
  border-radius: 10px;
  position: relative;
  z-index: 5;
  margin-top: -20%;
  box-shadow: 0 15px 15px 10px rgba(10, 10, 10, 0.2);
}
.popup-box span {
  margin-bottom: 20px;
}
.popup-box code {
  padding: 10px 5px;
  border: solid thin var(--light-blue-20);
  border-radius: 5px;
}
.popup-box code:hover {
  background-color: var(--light-blue-50);
}
