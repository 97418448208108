.signin {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  background-color: var(--light-blue-05);
  flex: 1;
}

.signin-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.signin h4 {
  text-align: center;
  margin: 0 auto;
}

.auth_notice h4 {
  margin-left: 10px;
}

.signin form {
  min-width: 340px;
}

.error {
  color: rgba(255, 100, 100, 0.9);
  padding: 10px;
  margin: 10px auto;
  text-align: center;
  display: block;
  background: rgba(255, 150, 150, 0.2);
  border-radius: 5px;
}

.form-wrapper {
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.form-control {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-bottom: 25px;
}

.signin .center {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
}

.form-control label {
  margin-bottom: 5px;
  font-weight: 700;
  font-size: 14px;
}

.form-control input {
  height: 40px;
  border-radius: 5px;
  padding: 0 10px;
  font-weight: 500;
  background-color: rgba(255, 255, 255, 1);
  border: solid 1px var(--light-blue-20);
  color: rgba(10, 10, 10, 0.5);
}

.form-control input:hover {
  border: solid 1px rgba(10, 10, 10, 0.1);
}

.auth_notice {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  margin: 15px auto;
}
.notice h4 {
  margin-left: 5px;
}

.pointer-area {
  cursor: pointer;
}

.consent-box {
  max-width: 340px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
}
.consent-box span {
  font-size: 14px;
  margin: 5px 0;
  text-align: left;
}
/* .consent-box input {
  max-width: 20px;
  max-height: 20px;
} */
