.button {
  padding: 15px;
  display: inline-flex;
  margin: 0;
}

.default {
  background-color: rgba(10, 10, 10, 0.9);
  border-radius: 5px;
  color: rgba(255, 255, 255, 1);
}
.default:hover {
  cursor: pointer;
  background-color: var(--blue);
}

.button span {
  font-weight: 700;
}

.button.dark {
  color: rgba(10, 10, 10, 0.9);
  background-color: rgba(255, 255, 255, 1);
}

.button.light {
  color: rgba(10, 10, 10, 0.9);
  background-color: transparent;
  border: 2px solid var(--blue);
}

.button.hide {
  display: none !important;
}
