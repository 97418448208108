@media screen and (max-width: 1240px) {
  .feedback-wrapper,
  .insight-panel-wrapper {
    padding: 0 2% !important;
  }
}

@media screen and (max-width: 900px) {
  .header {
    height: 80px !important;
  }
  .menu-list {
    display: none !important;
  }
  .logo img {
    height: 30px !important;
  }
  .hamburger,
  .mobile-menu {
    display: inline-block !important;
  }
  .banner {
    min-height: 250px;
  }
  .banner-left {
    width: 60% !important;
    /* background-color: antiquewhite; */
  }
  .banner-right {
    /* background-color: beige; */
    width: 40% !important;
    padding: 50px 0 !important;
  }
  .banner-right img {
    height: 200px;
  }

  h1 {
    font-size: 36px !important;
    margin: 15px auto;
  }
  .intro {
    min-height: 200px !important ;
  }
  .intro h3 {
    font-size: 18px !important;
  }
  .cta {
    min-height: 200px !important;
  }
  .cta-wrapper {
    padding: 40px !important;
  }
  .cta h3 {
    font-size: 18px !important;
  }

  .cards-wrapper {
    justify-content: space-evenly !important;
  }
  .card {
    width: 45% !important;
  }

  .feedback-form form {
    justify-content: center !important;
  }
  .feedback-form .form-control {
    width: 60% !important;
  }
  .comment-reply {
    width: 60% !important;
  }
  .comment-reply h4 {
    max-width: 100% !important;
  }

  .profile form {
    padding: 0 1%;
  }
  .pie-chart-box {
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }

  .pie-chart-box .chart {
    width: 40% !important;
  }
}

@media screen and (max-width: 720px) {
  .button {
    padding: 10px !important;
  }
  .banner-wrapper {
    flex-direction: column !important;
    text-align: center !important;
    padding: 0 !important;
    padding-top: 50px !important;
  }
  .side {
    width: 100% !important;
  }
  .banner-right {
    padding: 0 !important;
    margin: 0 !important;
  }
  .banner-right img {
    margin-bottom: -10px;
    margin-top: 40px;
  }

  .intro-wrapper {
    flex-direction: column !important;
    padding: 30px 0;
  }
  .intro .col {
    width: 100% !important;
    padding: 0 30px;
  }
  .intro h3 {
    text-align: center !important;
  }
  .pointers-wrapper {
    padding: 30px 0 !important;
  }
  .pointer {
    width: 60% !important;
  }

  .feedback-panel {
    padding-bottom: 20px !important;
  }
  .profile form {
    justify-content: center !important;
  }
  .profile .form-control {
    width: 70% !important;
  }

  .mobile {
    display: inline-block !important;
  }
  .desktop {
    display: none !important;
  }
  .pie-chart-box .chart {
    width: 80% !important;
  }
  .top-box {
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center;
  }

  .top-box .principle-text {
    width: 80% !important;
    max-width: 96% !important;
  }

  .top-box .principle-questions {
    width: 80% !important;
    max-width: 960% !important;
  }

  .footer-wrapper {
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;
  }
  .footer-wrapper h4 {
    font-size: 14px !important;
    margin: 5px 0 !important;
    text-align: center !important;
  }
}

@media screen and (max-width: 600px) {
  .card {
    width: 80% !important;
  }
  .feedback-form .form-control {
    width: 80% !important;
  }
  .profile .form-control {
    width: 80% !important;
  }
  .comment-reply {
    width: 80% !important;
  }

  .insight-box {
    margin-right: 2% !important;
    margin-left: 2% !important;
    padding: 20px !important;
  }
  .top-box .principle-text {
    width: 100% !important;
  }

  .top-box .principle-questions {
    width: 100% !important;
  }

  .comment-box {
    border-radius: 10px !important;
    padding: 20px 10px !important;
  }
  .comment-box ul {
    padding: 10px 10px !important;
  }
}

@media screen and (max-width: 520px) {
  .insight-box {
    width: 90% !important;
    max-width: 90% !important;
  }
  .chart-box .chart {
    max-width: 100% !important;
  }
  .top-box .principle-text {
    padding: 10px !important;
  }
  .top-box .principle-questions {
    padding: 10px !important;
  }
  .comment-avatar {
    width: 30px !important;
    height: 30px !important;
    min-width: 30px !important;
    min-height: 30px !important;
    margin-right: 10px !important;
  }
  .comment-meta {
    font-size: 14px !important;
    flex-wrap: wrap;
  }
  .comment-vote {
    width: 40% !important;
  }
  .comment-meta div {
    margin-right: 10px !important;
  }
  .comment-date {
    margin-top: 5px !important;
  }
}

@media screen and (max-width: 480px) {
  .button {
    padding: 7px 10px !important;
  }
  h1 {
    font-size: 28px !important;
    padding: 0 15px;
    margin: 15px auto;
  }
  .card {
    width: 95% !important;
  }
  .feedback-form .form-control {
    width: 90% !important;
  }
  .profile .form-control {
    width: 90% !important;
  }
  .comment-reply {
    width: 90% !important;
  }
}
